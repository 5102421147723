import { ProcuraReserva } from 'models/reserva';
import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { useSelector } from 'react-redux';
import TimePicker from 'react-time-picker/dist/TimePicker';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { convertCamps } from 'services/consts';
import { bloquearComReservas, bloquearHorario, bloquearProximoFimDeSemana, bloquearReservasComCriacaoDeReservas, bloquearReservasEndp, bloquearTotal, deleteBloqueio, retornaCamposIndisponíveisNaHora, retornaCamposIndisponíveisNaHoraENDP } from 'services/reservas/reservas_services';
import { selectbloquearReservas } from 'store/bloquear_reservas/bloquear_reservas_reducer';
import { selectclubeid } from 'store/localizacao/clube_id_reducer';
import { selectlocalizacao } from 'store/localizacao/localizacao_reducer';

function BloquearHorario() {
    const [isOpen, setIsOpen] = useState(false)
    const localizacao = useSelector(selectlocalizacao)
    const [horaInicial, setHoraInicial] = useState("00:00")
    const [horaFinal, setHoraFinal] = useState("00:00")
    const [startDate, setStartDate] = useState( new Date())
    const [endDate, setEndDate] = useState(  new Date())
    const [camposDisponiv, setCamposDisponiv] = useState([])
    const [isValidDate, setIsValidDate] = useState(true)
    const numeroDeCampos = 6

    const [isCamposIndisLoading, setIsCamposIndisLoading] = useState(false)

    const [dataValue, setDataValue] = useState(new Date())
    const minDate = new Date()

    const [isLoading, setIsLoading] = useState(false);

    const listaDeBlocks = useSelector(selectbloquearReservas)
    var listB = []
    for(const elem in listaDeBlocks) {
        listB.push(elem)
    } 
    /* const listaDeBlocksJson = JSON.parse(listaDeBlocks) */
    const clubeId = useSelector(selectclubeid)

  function toggle() {
    setIsOpen(!isOpen)
  }

  function removeCamposRepetidos(campoosInsdis) {
    var listaDeCampos = []
    var novaLista = []
    for (var i = 1; i <= numeroDeCampos; i++) {
      var stringAux = "Campo " + i
      if (campoosInsdis.includes(stringAux) == false) {
        listaDeCampos.push(stringAux)
      }
    }
    setCamposDisponiv(listaDeCampos/*  .concat(["Campo 1", "Campo 2"]) */  )
  }

  const [camposDisp, setCamposDisp] = useState([
    "Campo 1",
    "Campo 2",
    "Campo 3",
    "Campo 4",
    "Campo 5",
    "Campo 6",
  ])

  const [campoEscolhido, setCampoEscolhido] = useState([

  ])
  const removeCamposEsc = value => {
    setCampoEscolhido(campoEscolhido.filter(item => item !== value))
  }

  function restDados() {
    setStartDate(new Date())
    setEndDate(new Date())
    setCamposDisp([])
    setCamposDisponiv([])
    setCampoEscolhido([])
  }

  function diffInMinutes(date1, date2) {
    var diff = date2.valueOf() - date1.valueOf()
    var diffInMins = diff / 1000 / 60 
    console.log(diffInMins.toFixed(1))
    return diffInMins.toFixed(1)
  }

  function diffInHours(date1, date2) {
    var diff = date2.valueOf() - date1.valueOf()
    var diffInHours = diff / 1000 / 60 / 60
  
    return diffInHours.toFixed(1)
  }

    return (  
        <React.Fragment>
            <Button color='primary' onClick={() => {
              restDados()
              toggle()}}>
                Bloquear Horário
            </Button>
            <Modal isOpen={isOpen}
        toggle={() => {
          restDados() 
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader
          toggle={() => {
            restDados() 
            setIsOpen(!isOpen)
          }}
        >
          Bloquear Horário
        </ModalHeader>
        <ModalBody>
          <Form>
          <FormGroup row>
              <Label for="nome" md={3}>
                <p>Nome</p>
              </Label>
              <Col md={9}>
                <Input
                  type="text"
                  name="nomeInput"
                  id="nomeInput"
                  placeholder="nome"
                />
              </Col>
            </FormGroup>
          
            <FormGroup>
                <Row>
                    <Col md={3}>
                    <p>Horário Inicial</p>
                    </Col>
                    <Col md={9}>
                        <DateTimePicker
                            disableClock={true}
                            value={startDate} 
                            minDate={minDate}
                            onChange={(value) => {
                              setCamposDisponiv([])
                              setCampoEscolhido([])
                                
                                setStartDate(value)
                            }}>
                        </DateTimePicker>
                    </Col>
                </Row>
            
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={3}>
                    <p>Horário Final</p>
                    </Col>
                    <Col md={9}>
                    <DateTimePicker
                    disableClock={true}
                        value={endDate} 
                        minDate={minDate}  
                        onChange={(value) => {
                          setCamposDisponiv([])
                          setCampoEscolhido([])
                          
                            setEndDate(value)
                        }}>
                    </DateTimePicker>
                    </Col>
                </Row>
            
            </FormGroup>
            <FormGroup style={{ paddingTop: "20px" }}>
              {isCamposIndisLoading ? <Spinner color="primary"></Spinner> :
            <Button
              color="primary"
              onClick={async () => {
                setIsCamposIndisLoading(true)
                var copiedDate = startDate
                var procuraReserva = new ProcuraReserva()
                
                procuraReserva.duracaoDaReserva =
                diffInMinutes(startDate,endDate);
                procuraReserva.horaDaReserva = copiedDate
                procuraReserva.localizacao = localizacao
                await retornaCamposIndisponíveisNaHoraENDP(
                  procuraReserva
                ).then((value) => {
                 
                  removeCamposRepetidos(value['Ocupados'])  
                }) 
                setIsCamposIndisLoading(false)
              }}
              disabled={!isValidDate}
            >
              {" "}
              Mostrar Campos disponiveis
            </Button>}
            </FormGroup>
            <FormGroup>
            <h5 style={{paddingTop: "20px", paddingBottom: "10px"}}> Campos a bloquear </h5>
            <Row>
                {camposDisponiv.map((value, index) => {
                  return (
                    <Col key={index} md={4} style={{ paddingBottom: "10px" }}>
                      <Button
                        outline={campoEscolhido.includes(value) ? false : true}
                        color="primary"
                        onClick={() => {
                          if (!campoEscolhido.includes(value)) {
                            setCampoEscolhido(campoEscolhido => [
                              ...campoEscolhido,
                              value,
                            ])
                          } else {
                            removeCamposEsc(value)
                          }
                        }}
                      >
                        {convertCamps[value]}
                      </Button>
                    </Col>
                  )
                })}
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
         {/*  <Button onClick={async () => {
            await bloquearProximoFimDeSemana("Great Padel Vila Verde"
            , "2024 Tornieo fev", campoEscolhido )
          }}>
            Bloquear fds
          </Button>  */} 
           {isLoading ? <Spinner color='primary' ></Spinner> :
            <Button color='primary' onClick={async () => {
                if(listB.includes(document.getElementById("nomeInput").value)) {
                    alert("Nome repetido")
                } else if (document.getElementById("nomeInput").value.trim() == "") {
                    alert("O mome do campo não pode estar vazio")
                }
            else {
              setIsLoading(true)
              var userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
              var newInitialDate = new Date(startDate.getTime() - userTimezoneOffset);
              var newFinalDate = new Date(endDate.getTime() - userTimezoneOffset);

              var res = await bloquearComReservas(newInitialDate, newFinalDate, "Great Padel Vila Verde",
                clubeId,{}, document.getElementById("nomeInput").value, campoEscolhido  )
                setIsLoading(false)
                if(res) {
                    alert("Horário bloqueado com sucesso")
                    toggle()
                } else {
                    alert("Erro")
                    toggle()
                }}
            }} >Bloquear por reservas</Button>}
            
            <Button onClick={() => {toggle()}} >Cancelar </Button>

        </ModalFooter>
       
            </Modal>
        </React.Fragment>
    );
}

export default BloquearHorario;